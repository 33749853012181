import React from "react"
import footerStyles from '../css/footer.module.css'
import links from '../constants/links'
// import socialIcons from '../constants/social-icons'
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.links}>
        {links.map((item, index) => {
          return (
            <AniLink key={index} fade to={item.path}>
              {item.text}
            </AniLink>
          )
        })}
      </div>
      {/*<div className={footerStyles.icons}>*/}
      {/*  {socialIcons.map((item, index) => {*/}
      {/*    return (*/}
      {/*      <a key={index} href={item.url} target='_blank'  rel="noopener noreferrer">*/}
      {/*        {item.icon}*/}
      {/*      </a>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</div>*/}
      <div className={footerStyles.copyright}>
        copyright &copy; NolanJames {new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
