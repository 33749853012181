import React, { useState } from "react"
import navbarStyles from "../css/navbar.module.css"
import { GiMusicalScore } from "react-icons/gi"
import links from "../constants/links"
// import socialIcons from "../constants/social-icons"
import logo from "../images/logo_main.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Navbar = () => {
  const [isOpen, setNav] = (useState(false))
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <nav className={navbarStyles.navbar}>
      <div className={navbarStyles.navCenter}>
        <div className={navbarStyles.navHeader}>
          <img src={logo} alt="Logo"/>
          <button type='button' className={navbarStyles.logoBtn} onClick={toggleNav}>
            <GiMusicalScore className={navbarStyles.logoIcon}/>
          </button>
        </div>
        <ul className={isOpen ? `${navbarStyles.navLinks} ${navbarStyles.showNav}` :
          `${navbarStyles.navLinks}`}>
          {links.map((item, index) => {
            return (
              <li key={index}><AniLink fade to={item.path}>
                {item.text}
              </AniLink></li>)
          })}
        </ul>
        {/*<div className={navbarStyles.navSocialLinks}>*/}
        {/*  {socialIcons.map((item, index) => {*/}
        {/*    return (*/}
        {/*      <a key={index} href={item.url} target='_blank' rel="noopener noreferrer">*/}
        {/*        {item.icon}*/}
        {/*      </a>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</div>*/}
      </div>
    </nav>
  )
}

export default Navbar
