export default [
  {
    path: "/",
    text: "home",
  },
  // {
  //   path: "/services",
  //   text: "services",
  // },
  {
    path: "/portfolio",
    text: "portfolio"
  },
  {
    path: "/blogs",
    text: "blogs",
  },
  {
    path: "/contact",
    text: "contact",
  },
]
